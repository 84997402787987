import { navigate } from "gatsby"
import React, { useEffect } from "react"
import { BackgroundShapes } from "../components/BackgroundShapes"
import { Seo } from "../components/Seo/Seo"
import languages from "../constants/languages.json"
import { navigateToHome } from "../i18n/utils/getUrl"
import { SUBSCRIPTION_MODAL_SUCCESS_HASH } from "../utils/drawerUtils"
import logo from "../assets/logo/logo.svg"

export default function ThankYou() {
  useEffect(() => {
    const navigatorLanguages = (window.navigator?.languages || ["en"]).map(
      (lang) => lang.split("-")[0]
    )
    const findPrimaryLanguage =
      navigatorLanguages.find((language) => {
        return languages.find(({ id }) => id === language)
      }) || "en"

    window.location.href =
      navigateToHome(findPrimaryLanguage) +
      "#" +
      SUBSCRIPTION_MODAL_SUCCESS_HASH
  }, [])

  return (
    <>
      <Seo
        index={false}
        title="Thank you"
        lang={"en"}
        langUrls={[]}
        description="Thank you for your subscription"
      />

      <div className="h-screen flex items-center justify-center">
        <BackgroundShapes step={0} />
        <img
          src={logo}
          alt="sudoku academy"
          className="fixed top-0 lg:m-10 m-4 left-0 w-12"
        />
        <div className="flex items-center">
          <div className="w-4 h-4 mx-2 bg-blue-500 rounded-full animate-bounceHarder"></div>
          <div
            className="w-4 h-4 mx-2 bg-blue-500 rounded-full animate-bounceHarder"
            style={{ animationDelay: "50ms" }}
          ></div>
          <div
            className="w-4 h-4 mx-2 bg-blue-500 rounded-full animate-bounceHarder"
            style={{ animationDelay: "100ms" }}
          ></div>
        </div>
      </div>
    </>
  )
}
